// src/components/PrivateRoute.tsx
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import PlanExpiredNotice from './PlanExpiredNotice';
import { fetchAccountInfo } from '../features/account/accountSlice';

interface PrivateRouteProps {
	children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
	const dispatch = useDispatch<AppDispatch>();
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const planStatus = useSelector((state: RootState) => state.account.planStatus);
	const location = useLocation();

	useEffect(() => {
		if (isLoggedIn && !planStatus.status) {
			dispatch(fetchAccountInfo());
		}
	}, [dispatch, isLoggedIn, planStatus.status]);

	// If not logged in, redirect to login
	if (!isLoggedIn) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	// Show appropriate message based on plan status
	switch (planStatus.status) {
		case 'PLAN_EXPIRED':
			return (
				<PlanExpiredNotice
					planName={planStatus.details?.name || 'Unknown'}
					expiredAt={planStatus.details?.expiredAt}
				/>
			);

		case 'PLAN_REQUIRED':
			return <Navigate to="/signup" />;

		case 'ACTIVE':
			return children;

		default:
			// Show loading state while checking plan status
			return (
				<div className="flex items-center justify-center min-h-screen">
					<div className="w-8 h-8 border-4 border-primary-500 rounded-full animate-spin border-t-transparent"></div>
				</div>
			);
	}
};

export default PrivateRoute;