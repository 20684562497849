import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowLeft, CheckCircle2 } from 'lucide-react';
import { AppDispatch, RootState } from '../../app/store';
import { signupUser } from '../../features/auth/authSlice';
import { fetchPlans } from '../../features/plans/plansSlice';
import PaymentForm from './PaymentForm';
import './AuthForm.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

enum SignupStep {
    DETAILS,
    PLAN_SELECTION,
    PAYMENT
}

interface PlanCard {
    id: string;
    name: string;
    price: number;
    features: string[];
    maxGalleries: number;
    storageDuration: number;
}

const SignupForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [step, setStep] = useState<SignupStep>(SignupStep.DETAILS);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [error, setError] = useState('');

    const plans = useSelector((state: RootState) => state.plans.plans);
    const plansStatus = useSelector((state: RootState) => state.plans.status);

    useEffect(() => {
        if (plansStatus === 'idle') {
            dispatch(fetchPlans());
        }
    }, [dispatch, plansStatus]);

    const handleDetailsSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!name.trim() || !email.trim() || !password.trim()) {
            setError('All fields are required');
            return;
        }
        if (!validateEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }
        setError('');
        setStep(SignupStep.PLAN_SELECTION);
    };

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handlePlanSelection = async (planId: string) => {
        setSelectedPlanId(planId);
        try {
            const response = await dispatch(signupUser({
                email,
                password,
                name,
                planId
            })).unwrap();

            setClientSecret(response.clientSecret);
            setStep(SignupStep.PAYMENT);
        } catch (err: any) {
            setError(err.message || 'Failed to select plan');
        }
    };

    const handleGoBack = () => {
        if (step === SignupStep.PLAN_SELECTION) {
            setStep(SignupStep.DETAILS);
        } else if (step === SignupStep.PAYMENT) {
            setStep(SignupStep.PLAN_SELECTION);
        }
    };

    const formatPlanFeatures = (plan: any): PlanCard => ({
        id: plan.id,
        name: plan.name,
        price: plan.price,
        maxGalleries: plan.maxGalleries,
        storageDuration: plan.storageDuration,
        features: [
            `Up to ${plan.maxGalleries} galleries`,
            `${plan.storageDuration} months storage`,
            'Gallery sharing',
            'Automatic thumbnails',
            'Mobile upload support'
        ]
    });

    const getContainerClass = () => {
        const baseClass = 'auth-form-container';
        return step === SignupStep.PLAN_SELECTION ? baseClass : `${baseClass} narrow`;
    };

    const renderStepIndicator = () => (
        <div className="step-indicator">
            {[0, 1, 2].map((index) => (
                <div
                    key={index}
                    className={`step-dot ${index === step ? 'active' : ''}`}
                />
            ))}
        </div>
    );

    const renderStep = () => {
        switch (step) {
            case SignupStep.DETAILS:
                return (
                    <form onSubmit={handleDetailsSubmit} className="auth-form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="auth-input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="auth-input"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="auth-input"
                        />
                        {error && <div className="error-text">{error}</div>}
                        <button type="submit" className="auth-button">
                            Next
                        </button>
                    </form>
                );

            case SignupStep.PLAN_SELECTION:
                return (
                    <div className="auth-form">
                        <button onClick={handleGoBack} className="back-button">
                            <ArrowLeft size={20} />
                            Back
                        </button>
                        <h2 className="text-2xl font-bold text-center mb-6">Choose Your Plan</h2>
                        <div className="plans-grid">
                            {plans.map((plan) => {
                                const planCard = formatPlanFeatures(plan);
                                const isSelected = selectedPlanId === plan.id;
                                return (
                                    <div
                                        key={plan.id}
                                        className={`plan-card ${isSelected ? 'selected' : ''}`}
                                        onClick={() => handlePlanSelection(plan.id)}
                                    >
                                        <h3 className="plan-name">{planCard.name}</h3>
                                        <div className="plan-price">
                                            ${planCard.price}
                                            <span className="text-sm text-gray-500">/event</span>
                                        </div>
                                        <ul className="plan-features">
                                            {planCard.features.map((feature, index) => (
                                                <li key={index}>
                                                    <CheckCircle2 size={16} />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                        <button
                                            className={`auth-button ${isSelected ? '' : 'secondary'}`}
                                            onClick={() => handlePlanSelection(plan.id)}
                                        >
                                            {isSelected ? 'Selected' : 'Select Plan'}
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

            case SignupStep.PAYMENT:
                return clientSecret ? (
                    <div className="auth-form">
                        <button onClick={handleGoBack} className="back-button">
                            <ArrowLeft size={20} />
                            Back
                        </button>
                        <h2 className="text-2xl font-bold text-center mb-6">Payment Details</h2>
                        {clientSecret && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <PaymentForm redirectToProcessing={true} />
                            </Elements>
                        )}
                    </div>
                ) : (
                    <div className="text-center">Loading payment form...</div>
                );
        }
    };

    return (
        <div className="auth-container">
            <h1 className="auth-heading">Lumos</h1>
            <div className={getContainerClass()}>
                {renderStepIndicator()}
                {renderStep()}
            </div>
        </div>
    );
};

export default SignupForm;