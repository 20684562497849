const Logo = ({ className = '' }) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <img
        src="/lumos_logo_black.png"
        alt="Lumos"
        className="h-16 w-auto"
      />
    </div>
  );
};

export default Logo;