// src/App.tsx
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { setAuthToken } from './features/auth/authSlice';
import PrivateRoute from './components/PrivateRoute';
import LoginForm from './components/Auth/LoginForm';
import SignupForm from './components/Auth/SignupForm';
import PaymentProcessing from './components/Auth/PaymentProcessing';
import DashboardPage from './components/Dashboard/DashboardPage';
import AccountPage from './components/Account/AccountPage';
import PasswordResetRequestForm from './components/Auth/PasswordResetRequestForm';
import PasswordResetForm from './components/Auth/PasswordResetForm';
import GalleryDetailPage from './components/Gallery/GalleryDetailPage';
import AlbumDetailPage from './components/Album/AlbumDetailPage';
import Layout from './components/Common/Layout';
import NotFoundPage from './components/Common/NotFoundPage';
import AuthRedirect from './components/Common/AuthRedirect';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(setAuthToken(token));
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/request-password-reset" element={<PasswordResetRequestForm />} />
          <Route path="/reset-password" element={<PasswordResetForm />} />
          <Route path="/galleries/:galleryId" element={<GalleryDetailPage />} />
          <Route path="/galleries/:galleryId/albums/:albumId" element={<AlbumDetailPage />} />
          <Route path="/payment-processing" element={<PaymentProcessing />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <AccountPage />
              </PrivateRoute>
            }
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<AuthRedirect />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;