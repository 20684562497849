import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../app/store';
import { checkPaymentStatus } from '../../features/auth/authSlice';
import { AlertCircle, CheckCircle2 } from 'lucide-react';
import './PaymentProcessing.css';
import { clearPlanStatus, fetchAccountInfo } from '../../features/account/accountSlice';

const PaymentProcessing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.auth.userId);
  const paymentStatus = useSelector((state: RootState) => state.auth.paymentStatus);
  const error = useSelector((state: RootState) => state.auth.error);

  useEffect(() => {
    if (paymentStatus === 'succeeded') {
      const handleSuccess = async () => {
        dispatch(clearPlanStatus());
        await dispatch(fetchAccountInfo());
        navigate('/dashboard');
      };

      const timer = setTimeout(handleSuccess, 1500);
      return () => clearTimeout(timer);
    }
  }, [paymentStatus, navigate, dispatch]);

  useEffect(() => {
    const checkStatus = async () => {
      if (userId && paymentStatus === 'processing') {
        try {
          await dispatch(checkPaymentStatus(userId)).unwrap();
        } catch (error) {
          console.error('Error checking payment status:', error);
        }
      }
    };

    const interval = setInterval(checkStatus, 2000);
    return () => clearInterval(interval);
  }, [dispatch, userId, paymentStatus]);

  const getStatusConfig = () => {
    switch (paymentStatus) {
      case 'processing':
        return {
          icon: (
            <div className="w-16 h-16 relative">
              <div className="absolute inset-0 border-4 border-blue-200 rounded-full"></div>
              <div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
            </div>
          ),
          title: 'Processing Your Payment',
          description: 'Please wait while we confirm your transaction...',
          titleColor: 'text-gray-900'
        };
      case 'succeeded':
        return {
          icon: <CheckCircle2 className="w-16 h-16 text-green-500" />,
          title: 'Payment Successful!',
          description: 'Redirecting you to the dashboard...',
          titleColor: 'text-green-600'
        };
      case 'failed':
        return {
          icon: <AlertCircle className="w-16 h-16 text-red-500" />,
          title: 'Payment Failed',
          description: error || 'There was an error processing your payment.',
          titleColor: 'text-red-600'
        };
      default:
        return {
          icon: (
            <div className="w-16 h-16 relative">
              <div className="absolute inset-0 border-4 border-blue-200 rounded-full"></div>
              <div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
            </div>
          ),
          title: 'Initializing Payment',
          description: 'Setting up your payment process...',
          titleColor: 'text-gray-900'
        };
    }
  };

  const config = getStatusConfig();

  return (
    <div className="payment-processing-container">
      <div className="payment-card">
        <div className="payment-icon">
          {config.icon}
        </div>

        <h2 className={`payment-title ${config.titleColor}`}>
          {config.title}
        </h2>

        <p className="payment-description">
          {config.description}
        </p>

        {paymentStatus === 'failed' && (
          <button
            onClick={() => navigate('/signup')}
            className="retry-button"
          >
            Try Again
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentProcessing;