// src/features/account/accountSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

interface AccountInfo {
  plan: {
    id: string;
    name: string;
    maxGalleries: number;
    storageDuration: number;
    expiresAt: string;
    daysRemaining: number;
  };
  usage: {
    currentGalleries: number;
    remainingGalleries: number;
    percentageUsed: number;
  };
}

interface AccountState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  planStatus: {
    status: 'ACTIVE' | 'PLAN_EXPIRED' | 'PLAN_REQUIRED' | null;
    details?: {
      name?: string;
      expiredAt?: string;
    };
  };
  planChangeStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  planChangeError: string | null;
  accountInfo: AccountInfo | null;
}

const initialState: AccountState = {
  status: 'idle',
  error: null,
  planStatus: {
    status: null
  },
  planChangeStatus: 'idle',
  planChangeError: null,
  accountInfo: null
};

export const fetchAccountInfo = createAsyncThunk(
  'account/fetchInfo',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const response = await axios.get('/api/account/info', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const calculatePlanChange = createAsyncThunk(
  'account/calculatePlanChange',
  async (newPlanId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/payments/calculate-proration?newPlanId=${newPlanId}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to calculate plan change');
    }
  }
);

export const processPlanChange = createAsyncThunk(
  'account/processPlanChange',
  async ({ planId, refundAmount }: { planId: string; refundAmount?: number }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;
      console.log(`process plan change`)
      const response = await axios.post('/api/payments/process-plan-change', {
        planId,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response)
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to process plan change');
    }
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccountInfo: (state) => {
      state.accountInfo = null;
      state.status = 'idle';
      state.error = null;
      state.planStatus = {
        status: null
      };
    },
    setPlanStatus: (state, action) => {
      state.planStatus = {
        status: action.payload.status,
        details: action.payload.details
      };
    },
    clearPlanStatus: (state) => {
      state.planStatus = {
        status: null
      };
    },
    resetPlanChangeStatus: (state) => {
      state.planChangeStatus = 'idle';
      state.planChangeError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch account info cases
      .addCase(fetchAccountInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccountInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accountInfo = action.payload;
        // If account info is successfully fetched, plan is active
        state.planStatus = {
          status: 'ACTIVE'
        };
      })
      .addCase(fetchAccountInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      // Calculate plan change cases
      .addCase(calculatePlanChange.pending, (state) => {
        state.planChangeStatus = 'loading';
      })
      .addCase(calculatePlanChange.fulfilled, (state) => {
        state.planChangeStatus = 'succeeded';
      })
      .addCase(calculatePlanChange.rejected, (state, action) => {
        state.planChangeStatus = 'failed';
        state.planChangeError = action.payload as string;
      })
      .addCase(processPlanChange.pending, (state) => {
        state.planChangeStatus = 'loading';
      })
      .addCase(processPlanChange.fulfilled, (state) => {
        state.planChangeStatus = 'succeeded';
      })
      .addCase(processPlanChange.rejected, (state, action) => {
        state.planChangeStatus = 'failed';
        state.planChangeError = action.payload as string;
      });
  }
});

export const {
  clearAccountInfo,
  setPlanStatus,
  clearPlanStatus,
  resetPlanChangeStatus
} = accountSlice.actions;

export default accountSlice.reducer;