import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Package2, CalendarDays, Database, AlertCircle } from 'lucide-react';
import { fetchAccountInfo } from '../../features/account/accountSlice';
import { AppDispatch, RootState } from '../../app/store';
import GenericLayout from '../Common/GenericLayout';
import PlanChangeDialog from './PlanChangeDialog';

const COLORS = ['#3B82F6', '#E5E7EB'];

const Card = ({ children, className = '' }: any) => (
  <div className={`bg-white rounded-xl shadow-sm p-6 border border-gray-100 ${className}`}>
    {children}
  </div>
);

const Statistic = ({ label, value, icon: Icon }: any) => (
  <div className="flex items-center justify-between p-6 bg-white rounded-xl shadow-sm border border-gray-100">
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="text-2xl font-bold mt-1">{value}</p>
    </div>
    <Icon className="text-primary-500 h-8 w-8" />
  </div>
);

const AccountPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { status, accountInfo } = useSelector((state: RootState) => state.account);
  const [showPlanChange, setShowPlanChange] = useState(false);

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  if (status === 'loading' || !accountInfo) {
    return (
      <GenericLayout title="Account" backButton={false}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="w-8 h-8 border-4 border-primary-500 rounded-full animate-spin border-t-transparent" />
        </div>
      </GenericLayout>
    );
  }

  const usageData = [
    { name: 'Used', value: accountInfo.usage.currentGalleries },
    { name: 'Available', value: accountInfo.usage.remainingGalleries }
  ];

  return (
    <>
      <GenericLayout title="Account Overview" backButton={false}>
        <div className="max-w-7xl mx-auto px-4 py-8">
          {/* Key Statistics */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <Statistic
              label="Current Plan"
              value={accountInfo.plan.name}
              icon={Package2}
            />
            <Statistic
              label="Gallery Usage"
              value={`${Math.round(accountInfo.usage.percentageUsed)}%`}
              icon={Database}
            />
            <Statistic
              label="Days Remaining"
              value={accountInfo.plan.daysRemaining}
              icon={CalendarDays}
            />
          </div>

          {/* Usage Distribution */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <Card>
              <h3 className="text-lg font-semibold mb-6">Gallery Distribution</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={usageData}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {usageData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="flex justify-center mt-4 space-x-6">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-primary-500 mr-2" />
                  <span className="text-sm text-gray-600">Used ({accountInfo.usage.currentGalleries})</span>
                </div>
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-gray-200 mr-2" />
                  <span className="text-sm text-gray-600">Available ({accountInfo.usage.remainingGalleries})</span>
                </div>
              </div>
            </Card>

            {/* Plan Details */}
            <Card>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-semibold">Plan Details</h3>
                <button
                  onClick={() => setShowPlanChange(true)}
                  className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
                >
                  Change Plan
                </button>
              </div>
              <div className="space-y-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Maximum Galleries</p>
                  <p className="text-lg font-semibold mt-1">{accountInfo.plan.maxGalleries}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Storage Duration</p>
                  <p className="text-lg font-semibold mt-1">{accountInfo.plan.storageDuration} months</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">Expiration Date</p>
                  <p className="text-lg font-semibold mt-1">
                    {format(new Date(accountInfo.plan.expiresAt), 'MMMM d, yyyy')}
                  </p>
                </div>
              </div>
            </Card>
          </div>

          {/* Expiration Alert */}
          {accountInfo.plan.daysRemaining < 30 && (
            <div className="mt-8">
              <div className="bg-red-50 rounded-xl p-4 border border-red-100">
                <div className="flex">
                  <AlertCircle className="text-red-500 w-5 h-5 mr-3" />
                  <div>
                    <h4 className="text-red-800 font-medium">Plan Expiring Soon</h4>
                    <p className="text-red-700 text-sm mt-1">
                      Your plan will expire in {accountInfo.plan.daysRemaining} days. Consider renewing or upgrading your subscription
                      to avoid any service interruption.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </GenericLayout>

      {/* Plan Change Dialog */}
      {showPlanChange && (
        <PlanChangeDialog
          onClose={() => setShowPlanChange(false)}
          currentPlanId={accountInfo.plan.id}
        />
      )}
    </>
  );
};

export default AccountPage;