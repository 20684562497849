// src/features/plans/plansSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

interface Plan {
  id: string;
  name: string;
  maxGalleries: number;
  storageDuration: number;
  price: number;
}


interface PlanUsage {
  maxGalleries: number;
  currentGalleryCount: number;
  canCreateGallery: boolean;
}

interface PlansState {
  plans: Plan[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  usage: PlanUsage | null;
}

const initialState: PlansState = {
  plans: [],
  status: 'idle',
  error: null,
  usage: null
};


export const fetchPlanUsage = createAsyncThunk(
  'plans/fetchUsage',
  async (userId: string) => {
    const response = await axios.get(`/api/plans/usage/${userId}`);
    return response.data;
  }
);

export const fetchPlans = createAsyncThunk(
  'plans/fetchPlans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/plans');
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plans = action.payload;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch plans';
      })
      .addCase(fetchPlanUsage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlanUsage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.usage = action.payload;
      })
      .addCase(fetchPlanUsage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch plan usage';
      });
  },
});

export default plansSlice.reducer;