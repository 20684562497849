import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { formatLocalDateTime } from '../../utils/DateFormat';
import { AppDispatch, RootState } from '../../app/store';
import { fetchGalleryPreviews } from '../../features/gallery/gallerySlice';
import {
  selectGalleryPreviews,
  selectGalleryPreviewsLoading,
  selectGalleryPreviewsError
} from '../../selectors/gallerySelectors';
import styles from './GalleryGrid/GalleryGrid.module.css';
import EmptyState from '../Common/EmptyState';

export interface Gallery {
  id: string;
  name: string;
  createdAt: string;
  itemCount?: number;
}

interface GalleryCardProps {
  gallery: Gallery;
  onCardClick: (galleryId: string) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement>, galleryId: string) => void;
  showCreatedAt?: boolean;
  showDelete?: boolean;
}

const GalleryCard: React.FC<GalleryCardProps> = ({
  gallery,
  onCardClick,
  onDeleteClick,
  showCreatedAt = true,
  showDelete = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const previews = useSelector((state: RootState) => selectGalleryPreviews(state, gallery.id));
  const loading = useSelector((state: RootState) => selectGalleryPreviewsLoading(state, gallery.id));
  const error = useSelector((state: RootState) => selectGalleryPreviewsError(state, gallery.id));

  useEffect(() => {
    dispatch(fetchGalleryPreviews({ galleryId: gallery.id }));
  }, [dispatch, gallery.id]);

  if (error) {
    console.error('Error loading gallery previews:', error);
  }

  return (
    <div
      className={styles.card}
      onClick={() => onCardClick(gallery.id)}
    >
      <div className={styles.previewGrid}>
        {loading ? (
          <div className={styles.loadingPreview} />
        ) : previews.length > 0 ? (
          previews.slice(0, 4).map((preview, index) => (
            <div
              key={index}
              className={`${styles.previewItem} ${previews.length === 1 ? styles.single :
                previews.length === 2 ? styles.double :
                  ''
                }`}
            >
              <img
                src={preview.thumbnailUrl || preview.url}
                alt={`Gallery preview ${index + 1}`}
                className={styles.previewImage}
                loading="lazy"
              />
              {preview.type === 'video' && (
                <PlayCircleFilledWhiteIcon className={styles.videoIcon} />
              )}
            </div>
          ))
        ) : (
          <div className={styles.emptyPreview}>
            <EmptyState />
          </div>
        )}

        {showDelete && onDeleteClick && (
          <IconButton
            className={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(e, gallery.id);
            }}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>

      <div className={styles.cardContent}>
        <h3 className={styles.galleryName}>
          {gallery.name}
        </h3>

        <div className={styles.metadataContainer}>
          {showCreatedAt && (
            <div className={styles.timestamp}>
              <AccessTimeIcon className={styles.timestampIcon} />
              <span>{formatLocalDateTime(gallery.createdAt)}</span>
            </div>
          )}

          {gallery.itemCount !== undefined && (
            <span className={styles.itemCount}>
              {gallery.itemCount} items
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryCard;