import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Alert } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { confirmPayment } from '../../features/auth/authSlice';
import { useState } from 'react';

interface PaymentFormProps {
  onSuccess?: () => Promise<void>;
  redirectToProcessing?: boolean;
}

export default function PaymentForm({ onSuccess, redirectToProcessing = false }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const { paymentIntent, error: submitError } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (submitError) {
        setError(submitError.message || 'Payment failed');
        setIsProcessing(false);
        return;
      }

      if (paymentIntent) {
        // Confirm payment with backend
        await dispatch(confirmPayment(paymentIntent.id)).unwrap();

        if (onSuccess) {
          // If onSuccess callback is provided (like in plan change), call it
          await onSuccess();
        } else if (redirectToProcessing) {
          // If it's a new subscription, redirect to processing page
          navigate('/payment-processing');
        }
      }
    } catch (err: any) {
      setError(err.message || 'An unexpected error occurred');
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto p-6">
      <div className="mb-8">
        <PaymentElement />
      </div>

      {error && (
        <Alert intent="danger" marginBottom={16}>
          {error}
        </Alert>
      )}

      <Button
        type="submit"
        appearance="primary"
        isLoading={isProcessing}
        disabled={!stripe || isProcessing}
        width="100%"
      >
        {isProcessing ? 'Processing...' : 'Complete Payment'}
      </Button>
    </form>
  );
}