import React, { useState } from 'react';
import { AlertTriangle } from 'lucide-react';
import PlanRenewalFlow from './PlanRenewalFlow';

const PlanExpiredNotice = ({ planName, expiredAt }: any) => {
  const [showRenewal, setShowRenewal] = useState(false);

  if (showRenewal) {
    return <PlanRenewalFlow />;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <div className="flex flex-col items-center text-center">
          <div className="w-16 h-16 bg-yellow-100 rounded-full flex items-center justify-center mb-4">
            <AlertTriangle className="w-8 h-8 text-yellow-600" />
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-2">Plan Expired</h2>

          <p className="text-gray-600 mb-6">
            Your {planName} plan has expired. Don't worry - your data is safe and will be restored once you renew your plan.
          </p>

          <p className="text-sm text-gray-500 mb-6">
            Expired on: {new Date(expiredAt).toLocaleDateString()}
          </p>

          <button
            onClick={() => setShowRenewal(true)}
            className="w-full bg-primary-600 text-white px-6 py-3 rounded-lg font-medium 
                     hover:bg-primary-700 transition-colors"
          >
            Renew Plan
          </button>

          <p className="mt-4 text-sm text-gray-500">
            Need help? Contact our support team
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanExpiredNotice;