import { Card, CardContent, LinearProgress } from '@mui/material';
import { CloudUpload } from 'lucide-react';

export const UploadProgressIndicator = ({
  totalProgress,
  uploadedFilesCount,
  totalFiles
}: any) => {
  return (
    <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50 w-11/12 max-w-md px-4 pb-4">
      <Card className="bg-white shadow-lg">
        <CardContent className="p-6">
          <div className="flex items-start space-x-4">
            <div className="bg-blue-50 p-3 rounded-full">
              <CloudUpload className="w-5 h-5 text-blue-500" />
            </div>

            <div className="flex-1 min-w-0">
              <div className="flex justify-between items-start mb-1">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    Uploading files...
                  </h3>
                  <p className="text-xs text-gray-500 mt-0.5">
                    {uploadedFilesCount} of {totalFiles} completed
                  </p>
                </div>
                <span className="text-sm font-medium text-gray-900">
                  {Math.round(totalProgress)}%
                </span>
              </div>

              <div className="mt-3">
                <LinearProgress
                  variant="determinate"
                  value={totalProgress}
                  sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: '#EFF6FF',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 3,
                      backgroundColor: '#3B82F6'
                    }
                  }}
                />
              </div>

              {/* Added disclaimer */}
              <div className="mt-3 p-2 bg-yellow-50 rounded-md">
                <p className="text-xs text-yellow-800 font-medium">
                  Please don't close or refresh the page while media is being uploaded
                </p>
              </div>

              {uploadedFilesCount === totalFiles && (
                <p className="mt-2 text-xs text-gray-500 text-center">
                  Processing... Please wait while we finish up.
                </p>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};