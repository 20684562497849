import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowLeft, CheckCircle2, AlertCircle } from 'lucide-react';
import { AppDispatch, RootState } from '../app/store';
import { renewPlan } from '../features/auth/authSlice';
import { fetchPlans } from '../features/plans/plansSlice';
import PaymentForm from './Auth/PaymentForm';
import { fetchAccountInfo } from '../features/account/accountSlice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

enum RenewalStep {
  PLAN_SELECTION,
  PAYMENT
}

const PlanRenewalFlow = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [step, setStep] = useState<RenewalStep>(RenewalStep.PLAN_SELECTION);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [renewalError, setRenewalError] = useState<string | null>(null);

  const plans = useSelector((state: RootState) => state.plans.plans);
  const plansStatus = useSelector((state: RootState) => state.plans.status);

  useEffect(() => {
    if (plansStatus === 'idle') {
      dispatch(fetchPlans());
    }
  }, [dispatch, plansStatus]);

  const handlePlanSelection = async (planId: string) => {
    setSelectedPlanId(planId);
    setRenewalError(null);
    try {
      const response = await dispatch(renewPlan({ planId })).unwrap();

      if (!response.canRenew) {
        setRenewalError(response.reason);
        return;
      }

      setClientSecret(response.clientSecret);
      setStep(RenewalStep.PAYMENT);
    } catch (err: any) {
      setError(err.message || 'Failed to select plan');
      if (err.reason) {
        setRenewalError(err.reason);
      }
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      await dispatch(fetchAccountInfo());
      navigate('/dashboard');
    } catch (error) {
      setError('Failed to complete renewal process');
    }
  };

  const handleGoBack = () => {
    setStep(RenewalStep.PLAN_SELECTION);
    setRenewalError(null);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Step indicator */}
        <div className="flex justify-center space-x-2 mb-6">
          {[0, 1].map((index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full ${index === step ? 'bg-primary-500' : 'bg-gray-200'}`}
            />
          ))}
        </div>

        {renewalError && (
          <div className="mb-8 p-4 bg-red-50 rounded-lg border border-red-200">
            <div className="flex items-start">
              <AlertCircle className="w-5 h-5 text-red-500 mt-0.5 mr-3" />
              <div>
                <h3 className="text-lg font-semibold text-red-800 mb-1">
                  Unable to Renew Plan
                </h3>
                <p className="text-red-700">{renewalError}</p>
              </div>
            </div>
          </div>
        )}

        {step === RenewalStep.PLAN_SELECTION ? (
          <div>
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
              Choose Your New Plan
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {plans.map((plan) => {
                const isSelected = selectedPlanId === plan.id;
                return (
                  <div
                    key={plan.id}
                    className={`bg-white rounded-lg shadow-lg p-6 border-2 
                      ${isSelected ? 'border-primary-500' : 'border-transparent'}
                      transition-all duration-200 hover:transform hover:-translate-y-1
                      ${renewalError ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={() => !renewalError && handlePlanSelection(plan.id)}
                  >
                    <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
                    <div className="text-3xl font-bold text-primary-600 mb-4">
                      ${plan.price}
                      <span className="text-sm text-gray-500">/event</span>
                    </div>
                    <ul className="space-y-3">
                      <li className="flex items-center">
                        <CheckCircle2 className="w-5 h-5 text-primary-500 mr-2" />
                        <span>Up to {plan.maxGalleries} galleries</span>
                      </li>
                      <li className="flex items-center">
                        <CheckCircle2 className="w-5 h-5 text-primary-500 mr-2" />
                        <span>{plan.storageDuration} months storage</span>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-lg p-8">
            <button
              onClick={handleGoBack}
              className="flex items-center text-gray-600 mb-6 hover:text-gray-900"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Plans
            </button>
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
              Payment Details
            </h2>
            {clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm
                  onSuccess={handlePaymentSuccess}
                  redirectToProcessing={false}
                />
              </Elements>
            ) : (
              <div className="text-center text-gray-600">Loading payment form...</div>
            )}
          </div>
        )}

        {error && !renewalError && (
          <div className="mt-4 p-4 bg-red-50 rounded-lg text-red-700 text-sm">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}

export default PlanRenewalFlow;