import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { clearMediaFiles, deleteGallery, fetchGalleries } from '../../features/gallery/gallerySlice';
import GenericLayout from '../Common/GenericLayout';
import { AppDispatch, RootState } from '../../app/store';
import './DashboardPage.css';
import { selectIsLoading, selectGalleries } from '../../selectors/gallerySelectors';
import GalleriesGrid from '../Gallery/GalleryGrid/GalleryGrid';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import { fetchPlanUsage } from '../../features/plans/plansSlice';
import Logo from '../Common/Logo';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const galleries = useSelector(selectGalleries);
  const isLoading = useSelector(selectIsLoading);

  const canCreateGallery = useSelector((state: RootState) => state.plans.usage?.canCreateGallery);
  const userId = useSelector((state: RootState) => state.auth.userId);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [galleryIdToDelete, setGalleryIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      dispatch(fetchGalleries());
      dispatch(clearMediaFiles());
      dispatch(fetchPlanUsage(userId));
    }
  }, [dispatch, userId]);

  const handleCardClick = (galleryId: string) => {
    navigate(`/galleries/${galleryId}`);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, galleryId: string) => {
    event.stopPropagation();
    setGalleryIdToDelete(galleryId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (galleryIdToDelete) {
      await dispatch(deleteGallery(galleryIdToDelete));
      if (userId) {
        dispatch(fetchPlanUsage(userId));
      }
      setGalleryIdToDelete(null);
      setOpenDeleteDialog(false);
    }
  };

  return (
    <GenericLayout
      isLoading={isLoading}
      title={<Logo />}
      backButton={false}
      bottomBarProps={{
        canCreateGallery: canCreateGallery || false
      }}
    >
      <DeleteConfirmationModal
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        description="Deleting the gallery will also delete all albums and their associated photos/videos. Are you sure you want to proceed?"
      />

      <Box mb={2}>
        <Typography variant="h6" mb={2}>
          Galleries
        </Typography>
        <GalleriesGrid
          galleries={galleries}
          handleCardClick={handleCardClick}
          handleDeleteClick={handleDeleteClick}
          showCreatedAt={true}
          showDelete={true}
        />
      </Box>
    </GenericLayout>
  );
};

export default DashboardPage;