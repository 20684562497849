import React, { useRef, useEffect, MouseEvent } from 'react';
import GLightbox from 'glightbox';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import IconButton from '@mui/material/IconButton';
import 'glightbox/dist/css/glightbox.min.css';
import './PhotoGallery.css';
import { Media } from '../../types/media';
import videoPlaceholder from '../../images/video-placeholder.webp';

interface PhotoGalleryProps {
  photos: Media[];
  isOwner: boolean;
  isSelectMode: boolean;
  selectedPhotos: string[];
  onPhotoSelect: (selectedKeys: string[]) => void;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  photos,
  isOwner,
  isSelectMode,
  selectedPhotos,
  onPhotoSelect,
}) => {
  const galleryRef = useRef<ReturnType<typeof GLightbox> | null>(null);

  useEffect(() => {
    if (galleryRef.current) {
      galleryRef.current.destroy();
    }

    galleryRef.current = GLightbox({
      selector: '.gallery__item',
      openEffect: 'fade',
      closeEffect: 'fade',
      loop: true,
      zoomable: true,
    });

    return () => {
      if (galleryRef.current) {
        galleryRef.current.destroy();
      }
    };
  }, [photos]);

  const toggleSelectPhoto = (key: string, e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onPhotoSelect(
      selectedPhotos.includes(key)
        ? selectedPhotos.filter((selectedKey) => selectedKey !== key)
        : [...selectedPhotos, key]
    );
  };

  return (
    <div className="gallery">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}>
        <Masonry gutter="10px">
          {photos.map((media, index) => {
            const isSelected = selectedPhotos.includes(media.key);
            const showSelectIcon = isOwner || (media.userIdentifier && !isOwner);

            // Determine which URL to use for the preview
            const previewUrl = media.type === 'video' ?
              (media.thumbnailUrl || videoPlaceholder) :
              (media.thumbnailUrl || media.src);

            return (
              <div key={index} className="gallery__item-container">
                <a
                  href={media.src} // Always use original source for full view
                  className={`gallery__item ${isSelected ? 'selected' : ''}`}
                  data-type={media.type === 'video' ? 'video' : 'image'}
                  data-glightbox={media.type === 'video' ? 'type: video' : undefined}
                  data-title={media.userIdentifier ? `Uploaded by ${media.userIdentifier}` : ''}
                  aria-label={media.type === 'video' ? `Play video` : `View image`}
                >
                  <div className="video-thumbnail-container">
                    <img
                      className="img-responsive"
                      src={previewUrl}
                      alt={media.userIdentifier ? `Uploaded by ${media.userIdentifier}` : ''}
                      style={{ width: '100%', height: 'auto' }}
                      loading="lazy" // Enable lazy loading for better performance
                    />
                    {media.type === 'video' && (
                      <PlayCircleFilledWhiteIcon className="play-icon-overlay" />
                    )}
                  </div>
                </a>

                {isSelectMode && showSelectIcon && (
                  <div className="select-icon-container">
                    <IconButton
                      className="select-icon"
                      sx={{
                        color: isSelected ? 'green' : 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      }}
                      onClick={(e) => toggleSelectPhoto(media.key, e)}
                    >
                      {isSelected ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default PhotoGallery;