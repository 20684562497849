import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowLeft, CheckCircle2, X } from 'lucide-react';
import { AppDispatch, RootState } from '../../app/store';
import { fetchPlans } from '../../features/plans/plansSlice';
import { calculatePlanChange, processPlanChange, fetchAccountInfo } from '../../features/account/accountSlice';
import PaymentForm from '../Auth/PaymentForm';
import CustomSnackbar from '../Snackbar/Snackbar';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface PlanChangeDialogProps {
  onClose: () => void;
  currentPlanId: string;
}

interface ProratedAmounts {
  refundAmount: number;
  newCharge: number;
  canDowngrade: boolean;
  reason?: string;
}

const PlanChangeDialog = ({ onClose, currentPlanId }: PlanChangeDialogProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [proratedAmounts, setProratedAmounts] = useState<ProratedAmounts | null>(null);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const plans = useSelector((state: RootState) => state.plans.plans);

  // Fetch plans when component mounts
  useEffect(() => {
    const initializePlans = async () => {
      try {
        await dispatch(fetchPlans()).unwrap();
      } catch (err) {
        console.error('Failed to fetch plans:', err);
        setError('Failed to load available plans. Please try again.');
      }
    };
  
    initializePlans();
  }, [dispatch]);
  const handlePlanSelect = async (planId: string) => {
    if (planId === currentPlanId) return;

    try {
      setError('');
      setSelectedPlanId(planId);
      setIsProcessing(true);

      const result = await dispatch(calculatePlanChange(planId)).unwrap();
      setProratedAmounts(result);
      setClientSecret(result.clientSecret);

      if (!result.canDowngrade) {
        setError(result.reason || 'Cannot downgrade plan');
        setSelectedPlanId(null);
        setProratedAmounts(null);
        setClientSecret(null);
      }

    } catch (err: any) {
      setError(err.message || 'Failed to calculate plan change');
      setSelectedPlanId(null);
      setProratedAmounts(null);
      setClientSecret(null);
      setSnackbarMessage('Failed to process plan change');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Math.abs(price));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 transition-colors"
              disabled={isProcessing}
            >
              <ArrowLeft size={24} />
            </button>
            <h2 className="text-2xl font-bold text-center flex-grow">
              Change Your Plan
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 transition-colors"
              disabled={isProcessing}
            >
              <X size={24} />
            </button>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
              <div className="flex items-center text-red-700">{error}</div>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            {plans.map((plan) => {
              const isSelected = selectedPlanId === plan.id;
              const isCurrent = currentPlanId === plan.id;

              return (
                <div
                  key={plan.id}
                  className={`
                    relative p-6 rounded-lg border-2 transition-all 
                    ${isProcessing ? 'cursor-wait' : 'cursor-pointer'}
                    ${isCurrent ? 'opacity-50 cursor-not-allowed border-green-500 bg-green-50' :
                      isSelected ? 'border-blue-500 bg-blue-50' :
                        'border-gray-200 hover:border-blue-300'}
                  `}
                  onClick={() => !isCurrent && !isProcessing && handlePlanSelect(plan.id)}
                >
                  {isCurrent && (
                    <div className="absolute -top-3 -right-3 bg-green-500 text-white px-3 py-1 rounded-full text-sm">
                      Current Plan
                    </div>
                  )}

                  <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
                  <div className="text-3xl font-bold text-gray-900 mb-4">
                    {formatPrice(plan.price)}
                  </div>

                  <ul className="space-y-3 mb-6">
                    <li className="flex items-center">
                      <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                      <span>{plan.maxGalleries} galleries</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                      <span>{plan.storageDuration} months storage</span>
                    </li>
                  </ul>

                  {isCurrent && (
                    <div className="mt-4 text-sm text-gray-500">
                      This is your current plan
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {isProcessing && (
            <div className="text-center py-4 border-t">
              <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-primary-500 border-t-transparent"></div>
              <p className="mt-2 text-gray-600">Processing plan change...</p>
            </div>
          )}

          {selectedPlanId && !isProcessing && proratedAmounts && (
            <div className="border-t pt-6">
              <div className="text-center mb-6">
                <h3 className="text-lg font-semibold mb-2">Plan Change Summary</h3>

                <p className="text-gray-600 mb-2">
                  New Plan Cost: {formatPrice(proratedAmounts.newCharge)}
                </p>

                {proratedAmounts.refundAmount > 0 && (
                  <p className="text-green-600 mb-2">
                    Refund Amount: {formatPrice(proratedAmounts.refundAmount)}
                  </p>
                )}

                <p className="text-lg font-semibold mt-4">
                  Net Payment: {formatPrice(proratedAmounts.newCharge - proratedAmounts.refundAmount)}
                </p>

                <p className="text-sm text-gray-500 mt-2">
                  {proratedAmounts.refundAmount > 0
                    ? "You'll be charged for the new plan and refunded for unused time on your current plan"
                    : "You'll be charged for the new plan"}
                </p>
              </div>

              <div className="flex justify-center gap-4 mt-6">
                <button
                  onClick={() => {
                    setSelectedPlanId(null);
                    setProratedAmounts(null);
                  }}
                  className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                  disabled={isProcessing}
                >
                  Cancel
                </button>
                <button
                  onClick={() => setShowPaymentForm(true)}
                  className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Continue'}
                </button>
              </div>

              {showPaymentForm && clientSecret && (
                <div className="mt-6">
                  <div className="mb-4">
                    <button
                      onClick={() => setShowPaymentForm(false)}
                      className="text-gray-600 hover:text-gray-900 flex items-center"
                    >
                      <ArrowLeft className="w-4 h-4 mr-2" />
                      Back to Summary
                    </button>
                  </div>
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <PaymentForm
                      onSuccess={async () => {
                        try {
                          setIsProcessing(true);
                          await dispatch(processPlanChange({
                            planId: selectedPlanId!,
                          })).unwrap();
                          await dispatch(fetchAccountInfo());
                          setSnackbarMessage('Plan changed successfully');
                          setSnackbarSeverity('success');
                          setSnackbarOpen(true);
                          onClose();
                        } catch (err: any) {
                          setError(err.message || 'Failed to process plan change');
                          setSnackbarMessage('Failed to process plan change');
                          setSnackbarSeverity('error');
                          setSnackbarOpen(true);
                        } finally {
                          setIsProcessing(false);
                        }
                      }}
                    />
                  </Elements>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default PlanChangeDialog;