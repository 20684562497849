// src/axiosConfig.ts
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const setupAxiosInterceptors = (store: any) => {
    // Request interceptor to add auth token
    instance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Response interceptor to handle plan-related errors
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response) {
                // Handle plan-related errors
                if (error.response.status === 403) {
                    const errorCode = error.response.data?.code;

                    switch (errorCode) {
                        case 'PLAN_EXPIRED':
                        case 'PLAN_REQUIRED':
                            // Update Redux store with plan status
                            store.dispatch({
                                type: 'account/setPlanStatus',
                                payload: {
                                    status: errorCode,
                                    details: error.response.data.planDetails
                                }
                            });
                            break;

                        default:
                            // Handle other 403 errors
                            break;
                    }
                }

                // Handle authentication errors
                if (error.response.status === 401) {
                    store.dispatch({ type: 'auth/logout' });
                    store.dispatch({ type: 'account/clearAccountInfo' });
                }
            }

            return Promise.reject(error);
        }
    );
};

export default instance;