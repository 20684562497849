// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

interface SignupResponse {
    token: string;
    userId: string;
    clientSecret: string;
}

interface SignupData {
    email: string;
    password: string;
    name: string;
    planId: string;
}

interface AuthState {
    isLoggedIn: boolean;
    status: 'idle' | 'loading' | 'failed';
    paymentStatus: 'pending' | 'processing' | 'succeeded' | 'failed' | null;
    token: string | null;
    userId: string | null;
    clientSecret: string | null;
    error: string | null;
    resetRequestStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    resetStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    renewalStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    renewalError: string | null;
}


interface LoginResponse {
    token: string;
    userId: string;
}

const initialState: AuthState = {
    isLoggedIn: false,
    status: 'idle',
    paymentStatus: null,
    token: null,
    userId: null,
    clientSecret: null,
    error: null,
    resetRequestStatus: 'idle',
    resetStatus: 'idle',
    renewalStatus: 'idle',
    renewalError: null,
};

// Login user
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (userData: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post<LoginResponse>('/api/auth/login', userData);
            const { token, userId } = response.data;

            // Store token in localStorage
            localStorage.setItem('token', token);

            return { token, userId };
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Login failed');
        }
    }
);


// Create user account without payment
export const signupUser = createAsyncThunk<SignupResponse, SignupData>(
    'auth/signupUser',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/signup', userData);
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Signup failed');
        }
    }
);

// Request password reset
export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/request-password-reset', { email });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Failed to request password reset');
        }
    }
);

// Reset password
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ token, newPassword }: { token: string; newPassword: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/reset-password', {
                token,
                newPassword,
            });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Failed to reset password');
        }
    }
);

export const renewPlan = createAsyncThunk(
    'auth/renewPlan',
    async ({ planId }: { planId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/payments/renew-plan', { planId });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Renewal failed');
        }
    }
);

// Check payment status
export const checkPaymentStatus = createAsyncThunk(
    'auth/checkPaymentStatus',
    async (userId: string, { getState, rejectWithValue }) => {
        try {
            const state: any = getState();
            const token = state.auth.token;
            const response = await axios.get(`/api/payments/status/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            return response.data.status;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || 'Failed to check payment status');
        }
    }
);

// Confirm payment
export const confirmPayment = createAsyncThunk(
    'auth/confirmPayment',
    async (paymentIntentId: string, { getState, rejectWithValue }) => {
        try {
            const state: any = getState();
            const token = state.auth.token;

            const response = await axios.post('/api/payments/confirm',
                { paymentIntentId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            return response.data;
        } catch (err: any) {
            if (err.response?.status === 202) {
                // Payment is still processing, not a failure
                return { status: 'processing' };
            }
            return rejectWithValue(err.response?.data || 'Payment confirmation failed');
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload;
            state.isLoggedIn = !!action.payload;
            // Note: We'll need to fetch the userId separately when restoring from localStorage
            // since we don't have it available here
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.token = null;
            state.userId = null;
            state.clientSecret = null;
            state.error = null;
            state.paymentStatus = null;
            localStorage.removeItem('token');
        },
        clearError: (state) => {
            state.error = null;
        },
        resetPaymentStatus: (state) => {
            state.paymentStatus = 'pending';
        },
        resetPasswordResetStatuses: (state) => {
            state.resetRequestStatus = 'idle';
            state.resetStatus = 'idle';
            state.error = null;
        },
        clearRenewalStatus: (state) => {
            state.renewalStatus = 'idle';
            state.renewalError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Login cases
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.token = action.payload.token;
                state.userId = action.payload.userId;
                state.status = 'idle';
                state.error = null;
                state.paymentStatus = 'succeeded';
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
                state.isLoggedIn = false;
                state.token = null;
                state.userId = null;
            })
            // Signup cases
            .addCase(signupUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                state.status = 'idle';
                state.token = action.payload.token;
                state.userId = action.payload.userId;
                state.clientSecret = action.payload.clientSecret;
                state.paymentStatus = 'pending';
                state.error = null;
            })
            .addCase(signupUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Password reset request cases
            .addCase(requestPasswordReset.pending, (state) => {
                state.resetRequestStatus = 'loading';
                state.error = null;
            })
            .addCase(requestPasswordReset.fulfilled, (state) => {
                state.resetRequestStatus = 'succeeded';
                state.error = null;
            })
            .addCase(requestPasswordReset.rejected, (state, action) => {
                state.resetRequestStatus = 'failed';
                state.error = action.payload as string;
            })
            // Password reset cases
            .addCase(resetPassword.pending, (state) => {
                state.resetStatus = 'loading';
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.resetStatus = 'succeeded';
                state.error = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.resetStatus = 'failed';
                state.error = action.payload as string;
            })
            // Payment status check cases
            .addCase(checkPaymentStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(checkPaymentStatus.fulfilled, (state, action) => {
                state.status = 'idle';
                state.paymentStatus = action.payload;
                if (action.payload === 'succeeded') {
                    state.isLoggedIn = true;
                    localStorage.setItem('token', state.token!);
                }
            })
            .addCase(checkPaymentStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Payment confirmation cases
            .addCase(confirmPayment.pending, (state) => {
                state.status = 'loading';
                state.paymentStatus = 'processing';
            })
            .addCase(confirmPayment.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.status === 'processing') {
                    state.paymentStatus = 'processing';
                } else {
                    state.paymentStatus = 'succeeded';
                    state.isLoggedIn = true;
                    if (state.token) {
                        localStorage.setItem('token', state.token);
                    }
                }
            })
            .addCase(confirmPayment.rejected, (state, action) => {
                state.status = 'failed';
                state.paymentStatus = 'failed';
                state.error = action.payload as string;
            })
            .addCase(renewPlan.pending, (state) => {
                state.renewalStatus = 'loading';
                state.renewalError = null;
            })
            .addCase(renewPlan.fulfilled, (state) => {
                state.renewalStatus = 'succeeded';
                state.renewalError = null;
            })
            .addCase(renewPlan.rejected, (state, action) => {
                state.renewalStatus = 'failed';
                state.renewalError = action.payload as string;
            });
    },
});

export const {
    setAuthToken,
    logout,
    clearError,
    resetPaymentStatus,
    resetPasswordResetStatuses,
    clearRenewalStatus
} = authSlice.actions;

export default authSlice.reducer;