import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import BottomBar from './BottomBar/BottomBar';
import TopBar from './TopBar';
import './GenericLayout.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

interface GenericLayoutProps {
  children: React.ReactNode;
  isLoading?: boolean;
  title?: React.ReactNode | string;
  description?: string;
  actions?: React.ReactNode;
  backButton?: boolean;
  editable?: boolean;
  onTitleChange?: (newTitle: string, newDescription: string, isPasswordProtected: boolean) => void;
  initialPasswordProtection?: boolean;
  accessPin?: string;
  bottomBarProps?: {
    canCreateGallery?: boolean;
  };
}

const GenericLayout = ({
  children,
  isLoading = false,
  title,
  description,
  actions,
  backButton = true,
  editable = false,
  onTitleChange,
  initialPasswordProtection = false,
  accessPin,
  bottomBarProps = {}
}: GenericLayoutProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
    <div className="generic-layout">
      <div className="main-content">
        <TopBar
          title={title}
          description={description}
          actions={actions}
          backButton={backButton}
          editable={editable}
          onTitleChange={onTitleChange}
          initialPasswordProtection={initialPasswordProtection}
          accessPin={accessPin}
        />
        <div className="content-area">
          {isLoading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div className="main-container">
              {children}
            </div>
          )}
        </div>
      </div>
      {isLoggedIn && <BottomBar {...bottomBarProps} />}
    </div>
  );
};

export default GenericLayout;