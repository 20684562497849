// src/components/Common/BottomBar/actions/DashboardActions.tsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined, LoginOutlined, AddCircleOutline, AccountCircleOutlined } from '@mui/icons-material';
import { BottomNavigationAction, styled } from '@mui/material';
import { AppDispatch, RootState } from '../../../../app/store';
import { createGallery } from '../../../../features/gallery/gallerySlice';
import CreateModal from '../../CreateModal';
import { fetchPlanUsage } from '../../../../features/plans/plansSlice';

interface DashboardActionsProps {
  onNavigateHome: () => void;
  onLogout: () => void;
  canCreateGallery: boolean;
}

const DisabledNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  '&.Mui-disabled': {
    opacity: 0.4,
    '& .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    }
  },
}));

const DashboardActions: React.FC<DashboardActionsProps> = ({
  onNavigateHome,
  onLogout,
  canCreateGallery
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector((state: RootState) => state.auth.userId);
  const [openModal, setOpenModal] = useState(false);

  const handleCreateGallery = async (name: string, description: string, isPasswordProtected: boolean) => {
    await dispatch(createGallery({ name, description, isPasswordProtected }));
    if (userId) {
      dispatch(fetchPlanUsage(userId));
    }
    setOpenModal(false);
  };

  return (
    <>
      <BottomNavigationAction
        label="Home"
        icon={<HomeOutlined />}
        onClick={onNavigateHome}
      />
      <DisabledNavigationAction
        label="Add Gallery"
        icon={<AddCircleOutline />}
        onClick={() => setOpenModal(true)}
        disabled={!canCreateGallery}
      />
      <BottomNavigationAction
        label="Account"
        icon={<AccountCircleOutlined />}
        onClick={() => navigate('/account')}
      />
      <BottomNavigationAction
        label="Logout"
        icon={<LoginOutlined />}
        onClick={onLogout}
      />

      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleCreateGallery}
        title="Create New Gallery"
        label="Gallery Name"
        type="gallery"
      />
    </>
  );
};

export default DashboardActions;